// Cache all parallax elements to avoid further queries
const parallaxContainers = document.querySelectorAll('.parallax-container');

// Increase size of element in order to prevent any exposed gap on scroll
const adjustLayerHeight = () => {
  parallaxContainers.forEach(container => {
    const containerHeight = container.offsetHeight * 1.33;
    const layers = container.querySelectorAll('.parallax-layer');
    layers.forEach(layer => {
      layer.style.height = containerHeight + 'px';
    });
  });
};

// Prevent parallax from running until item is close to viewport to help elements further down the page
const isElementInViewport = el => {
  const rect = el.getBoundingClientRect();
  return rect.top <= window.innerHeight + 50 && rect.bottom >= 0;
};

// Parallax movement:
// Multiple layers within the same container will offset speeds from each other based on order.
// Each parallax container moves relative to where it is in the rendered HTML document
const parallaxScroll = () => {
  const parallaxScrollTop = window.scrollY;
  parallaxContainers.forEach(container => {
    const distanceFromTop = container.offsetTop;
    if (isElementInViewport(container)) {
      const scrollDistance = parallaxScrollTop - distanceFromTop;
      const layers = container.querySelectorAll('.parallax-layer');
      layers.forEach((layer, index) => {
        const yPos = scrollDistance * (index + 1) * 0.2;
        layer.style.transform = `translateY(${yPos}px)`;
      });
    }
  });
};

// Listen & run relevant functions on load, scroll, and resize
window.addEventListener('load', () => {
  const parallaxContainersExist = parallaxContainers.length > 0;
  if (parallaxContainersExist) {
    adjustLayerHeight();
    parallaxScroll();
    window.addEventListener('resize', adjustLayerHeight);
    window.addEventListener('scroll', parallaxScroll);
  }
});
